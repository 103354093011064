<template>
  <div>
    <BaseTable
      :headers="headers"
      :items="campanhas.data"
      :search="search"
      sort-by="data_cad"
      :sort-desc="true"
      :loading="loading"
      :paginate="true"
      class="mt-4 table-shadow"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Campanhas</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            clearable
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>

          <v-btn
            text
            v-if="cadastro"
            v-can-access="91"
            :to="{ name: 'cadastro-capa-campanha' }"
          >
            <v-icon class="mr-1">mdi-plus</v-icon>
            Cadastrar
          </v-btn>
          <RelatorioXlsx
            consultaCampanha
            fileName="campanhas"
            :filter="filters"
            label="Relatório"
          />
          <FilterCampaign
            @selectedFilters="fetchCampanhas((filters = $event))"
          />
        </v-toolbar>
      </template>

      <template v-slot:[`item.valor_custo`]="{ item }">
        {{ item.valor_custo | BrazilianCurrency }}
      </template>

      <!-- <template v-slot:[`item.apelido`]="{ item }">
        <template v-if="Number(item.total_empresas) > 0">
          <v-badge
            v-if="Number(item.total_empresas) > 1"
            bordered
            :content="'+' + (Number(item.total_empresas) - 1)"
          >
            {{ item.apelido }}
          </v-badge>
          <template v-else>
            {{ item.apelido }}
          </template>
        </template>
        <template v-else>
          Não há empresas vinculadas
        </template>
      </template> -->

      <template v-slot:[`item.nome_campanha`]="{ item }">
        {{ item.nome_campanha | TitleCase }}
      </template>

      <template v-slot:[`item.perc_crescimento`]="{ item }">
        {{ item.perc_crescimento }}%
      </template>

      <template v-slot:[`item.data_inicio`]="{ item }">
        {{ item.data_inicio | BrazilianStandardDate }} -
        {{ item.data_final | BrazilianStandardDate }}
      </template>
      <template v-slot:[`item.data_cad`]="{ item }">
        {{ item.data_cad | BrazilianStandardDate }}
      </template>
      <template v-slot:[`item.usercrioucampanha`]="{ item }">
        {{ item.usercrioucampanha | TitleCase }}
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-icon color="success" v-if="item.status == 'S'">
          mdi-check
        </v-icon>
        <v-icon color="error" v-else-if="item.status == 'N'">
          mdi-close
        </v-icon>
        <v-icon color="gray" v-else>
          mdi-dots-horizontal
        </v-icon>
      </template>

      <template v-slot:[`item.id_status_camp`]="{ item }">
        <v-icon :color="statusCampanha(item.id_status_camp).color">{{
          statusCampanha(item.id_status_camp).icon
        }}</v-icon>
      </template>

      <template
        v-for="(_, scopedSlotName) in $scopedSlots"
        v-slot:[scopedSlotName]="slotData"
      >
        <slot :name="scopedSlotName" v-bind="slotData" />
      </template>
    </BaseTable>
  </div>
</template>

<script>
import dateMixin from "@/mixins/dateMixin";
import BaseTable from "@/components/shared/NewBaseTable";
import currencyMixin from "@/mixins/currencyMixin";
import noDataMixin from "@/mixins/noDataMixin";

import FilterCampaign from "./consulta/FilterCampaign";

import campanha from "@/services/http/campanhaService";

import RelatorioXlsx from "./RelatorioXlsx";
import { mapMutations } from "vuex";

export default {
  name: "newTableCampanhas",

  components: {
    BaseTable,
    FilterCampaign,
    RelatorioXlsx
  },

  props: {
    cadastro: Boolean,
    action: Boolean
  },

  mixins: [dateMixin, currencyMixin, noDataMixin],

  data() {
    return {
      dialog: false,
      dialogDelete: false,
      search: "",
      filters: {},
      loading: false,
      headers: [
        { text: "ID", value: "id_campanha", fixed: true },
        {
          text: "Campanha",
          align: "start",
          value: "nome_campanha"
        },
        {
          text: "Custo Estimado",
          value: "valor_custo",
          align: "right"
        },
        {
          text: "% Cresc.",
          value: "perc_crescimento",
          align: "right"
        },
        { text: "Tipo", value: "tipo_dado" },
        { text: "Período", value: "data_inicio" },
        { text: "Criado Por", value: "usercrioucampanha" },
        {
          text: "Cadastro",
          value: "status",
          align: "center"
        },
        { text: "Status", value: "id_status_camp" }
      ],

      campanhas: {
        loading: false,
        data: []
      },

      formatStatus: {
        2: {
          text: "Análise",
          icon: "mdi-timer-sand",
          color: "warning"
        },
        21: {
          text: "Andamento",
          icon: "mdi-progress-clock",
          color: "success"
        },
        1: {
          text: "Aprovada",
          icon: "mdi-thumb-up-outline",
          color: "primary"
        },
        23: {
          text: "Expirada",
          icon: "mdi-timer-sand-full",
          color: "error"
        },
        22: {
          text: "Finalizada",
          icon: "mdi-check-all",
          color: "info"
        },
        3: {
          text: "Reprovado",
          icon: "mdi-thumb-down-outline",
          color: "red"
        },
        24: {
          text: "Indefinido",
          icon: "mdi-help-circle-outline",
          color: "secundary"
        }
      }
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    }
  },

  async mounted() {
    this.setCurrentCampanha("");

    if (this.action) {
      this.headers.push({
        text: "Ações",
        value: "actions",
        align: "center",
        sortable: false
      });
    }

    if (this.currentRouteName !== "consulta-campanha") {
      this.headers = this.headers.filter(item => item.text !== "Cadastramento");
    }
  },

  methods: {
    ...mapMutations({
      setCurrentCampanha: "campanha/setCurrentCampanha"
    }),

    async fetchCampanhas(filter = {}) {
      this.loading = true;
      let { data } = await campanha().show({
        per_page: -1,
        ...filter
      });
      this.loading = false;
      this.campanhas.data = data.data;
    },

    statusCampanha(item) {
      return this.formatStatus[item];
    }
  }
};
</script>
